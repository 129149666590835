<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      title="Historial de ingresos"
      lazy
    >
      <inFlow-list
        :key="inFlowListKey"
        @addInFlowTab="newTab($event)"
      />
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <b-tab
      title="Nuevo ingreso"
      lazy
    >
      <inFlow-add
        @changeTab="changeTab($event)"
        @addInFlowTab="newTab($event)"
      />
    </b-tab>

    <!-- Dynamic tab for inFlow view -->
    <b-tab
      v-for="inFlow in inFlowsTabs"
      :key="'inFlow-tab-' + inFlow"
      :title="'Ingreso #' + inFlow"
      lazy
    >
      <inFlow-view :in-flow-id="inFlow" />
    </b-tab>

    <!-- Close tab button -->
    <template
      v-if="inFlowsTabs.length > 0"
      #tabs-end
    >
      <b-nav-item
        @click.prevent="closeTab(inFlowsTabs[0])"
      >
        <b>x</b>
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
// import axios from '@axios'
import InFlowList from './InFlowsList.vue'
import InFlowAdd from './InFlowsAdd.vue'
import InFlowView from './InFlowView.vue'

export default {
  components: {
    InFlowList,
    InFlowAdd,
    InFlowView,
  },

  data() {
    return {
      tabIndex: 1,
      inFlowListKey: 0,
      inFlowsTabs: [],

      cashData: {},
    }
  },

  methods: {
    changeTab(tabIndex) {
      this.tabIndex = tabIndex // Change tab
      this.inFlowListKey += 1 // Re-Render component
    },

    newTab(inFlowId) {
      this.inFlowsTabs = []
      this.inFlowsTabs.push(inFlowId)
      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    closeTab(x) {
      for (let i = 0; i < this.inFlowsTabs.length; i += 1) {
        if (this.inFlowsTabs[i] === x) {
          this.inFlowsTabs.splice(i, 1)
        }
      }
      this.tabIndex = 0 // Redirecto to list
    },
  },
}
</script>
