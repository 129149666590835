<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
        @submit.prevent="onSubmit"
      >
        <b-row class="invoice-add">

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              class="in-flow-add-wrapper"
              no-body
            >
              <b-card-body class="invoice-padding form-item-section">
                <div class="d-none d-lg-flex">
                  <!-- Headers -->
                  <b-row class="flex-grow-1 px-1">
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b>Producto</b>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b>Cantidad</b>
                    </b-col>
                  </b-row>
                  <div class="form-item-action-col" />
                </div>

                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(product, index) in inFlowData.products"
                    :key="index"
                    ref="row"
                  >
                    <!-- Item Form -->
                    <b-col cols="12">
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 pt-2 pl-1 pr-1">
                          <!-- Inputs -->
                          <!-- Input: product -->
                          <b-col
                            cols="12"
                            lg="8"
                          >
                            <label class="d-inline d-lg-none">Producto</label>
                            <v-select
                              :options="productsOptions"
                              :reduce="product => product"
                              input-id="select-products"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccione producto"
                              @search="onSearchProducts"
                              @input="val => updateItem(index, val)"
                            >
                              <template slot="no-options">
                                Lo siento, no se encontraron productos
                              </template>
                            </v-select>
                          </b-col>
                          <!-- Input: quantity -->
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="product.quantity"
                              type="number"
                              min="1"
                              step="any"
                              class="mb-2"
                            />
                          </b-col>
                        </b-row>
                        <!-- Icon: remove product -->
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="outline-warning"
                  size="sm"
                  class="mt-2"
                  @click="addItem"
                >
                  Agregar producto
                </b-button>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Observaciones: </span>
                <b-form-textarea
                  v-model="inFlowData.comments"
                  placeholder="Comentarios y anotaciones relacionadas con el ingreso..."
                />
              </b-card-body>

            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <!-- Action Buttons -->
            <b-card>
              <!-- Button: Save -->
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-success"
                block
                type="submit"
              >
                Ingresar productos
              </b-button>
            </b-card>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      // in-flow
      inFlowData: {
        comments: '',
        type: 'inflow',
        products: [JSON.parse(JSON.stringify(
          { // Detail template
            product_id: null,
            quantity: 0,
          },
        ))],
      },

      // Detail template
      formProductBlank: {
        product_id: null,
        quantity: 0,
      },

      // Products
      productsOptions: [],
      show: true,
    }
  },

  mounted() {
    this.initTrHeight()
  },

  created() {
    this.getProducts()
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    getProducts() {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      axios
        .get(`product?perPage=10&sortField=id&sortDesc=desc&filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          response.data.data.forEach(element => {
            this.productsOptions.push({
              label: element.name,
              id: element.id,
              cost: element.cost,
              description: element.description,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchProducts(search, loading) {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`product?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.productsOptions = []
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  label: element.name,
                  id: element.id,
                  cost: element.cost,
                  description: element.description,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getProducts()
      }
    },

    onSubmit(event) {
      event.preventDefault()
      this.saveData()
    },

    saveData() {
      this.$bvModal
        .msgBoxConfirm('¿Deseas hacer el ingreso de productos?', {
          title: 'Confirmar ingreso',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .post('flow-inventory', this.inFlowData)
              .then(async response => {
                if (response.data.res) {
                  this.clearForm()
                  this.makeToast('success', 'Nuevo ingreso', `Se ha realizado el ingreso correctamente, transacción: #${response.data.data.id}.`)
                  await new Promise(resolve => { window.setTimeout(resolve, 1000) })
                  this.$emit('addInFlowTab', response.data.data.id)
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    clearForm() {
      // Reset our form values
      this.inFlowData.comments = ''
      this.inFlowData.products = []

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    addItem() {
      this.$refs.form.style.overflow = 'hidden'
      this.inFlowData.products.push(JSON.parse(JSON.stringify(this.formProductBlank)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },

    removeItem(index) {
      this.inFlowData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    updateItem(index, val) {
      const foundItem = this.inFlowData.products.find(element => element.product_id === val.id)
      if (foundItem) {
        this.makeToast('danger', 'Producto ya seleccionado', `El producto ${val.label} ya fue seleccionado anteriormente, edite la cantidad si es necesario.`)
        this.removeItem(index)
      } else {
        const { id, description } = val
        this.inFlowData.products[index].product_id = id
        this.inFlowData.products[index].quantity = 1
        this.inFlowData.products[index].description = description
      }
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.in-flow-add-wrapper {
  .add-new-supplier-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.in-flow-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .in-flow-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .in-flow-total-title {
      margin-bottom: 0.35rem;
    }
    .in-flow-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}

.add-new-product-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
