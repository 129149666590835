<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          class="in-flow-add-wrapper"
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-alert
                  v-if="anulated"
                  variant="danger"
                  show
                >
                  <h4 class="alert-heading">
                    Anulado
                  </h4>
                  <div class="alert-body">
                    <span>Este ingreso ha sido anulado.</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <!-- Col: Proveedor -->
              <b-col
                cols="12"
                xl="12"
                md="12"
              >
                <h6 class="mb-1 mt-1">
                  Origen:
                </h6>
                Distribuidora Ferretera del Sur
              </b-col>

            </b-row>
          </b-card-body>

          <b-card-body class="invoice-padding form-item-section">
            <b-table
              responsive="sm"
              :items="inFlowData.products"
              :fields="inFlowfields"
            />
          </b-card-body>

          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Ingresado al sistema:
                </h6>
                {{ inFlowData.created_at }}
              </b-col>
              <b-col
                cols="12"
                xl="4"
                md="4"
              >
                <h6 class="mb-1">
                  Ingresado por:
                </h6>
                {{ inFlowData.user }}
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Observaciones: </span>
            {{ inFlowData.comments }}
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card v-if="!anulated">
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-danger"
            block
            @click="inFlowCancel"
          >
            Anular
          </b-button>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {

  directives: {
    Ripple,
  },

  props: {
    inFlowId: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      inFlowData: {
        in_flow_date: '',
        comments: '',
        products: [],
        created_at: '',
        user: '',
      },

      inFlowfields: [
        { key: 'product', label: 'Producto' },
        { key: 'qty', label: 'Cantidad', variant: 'success' },
      ],

      anulated: false,
    }
  },

  mounted() {
    if (this.inFlowId > 0) {
      this.getInFlow()
    }
  },

  methods: {
    getInFlow() {
      axios
        .get(`flow-inventory/${this.inFlowId}`)
        .then(response => {
          this.inFlowData.comments = (response.data.data.comments) ? response.data.data.comments : '-'
          this.inFlowData.products = response.data.data.products
          this.inFlowData.created_at = response.data.data.created_at
          this.inFlowData.user = response.data.data.user

          if (response.data.data.status === 'Inactive') {
            this.anulated = true
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    inFlowCancel() {
      this.$bvModal
        .msgBoxConfirm('Esta acción no se puede revertir. Cancelará el documento y rebajará el inventario de los productos.', {
          title: '¿Anular el ingreso?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`flow-inventory/cancel/${this.inFlowId}`)
              .then(response => {
                if (response.data.res) {
                  this.makeToast('success', 'Ingreso anulado', `Se anuló el ingreso, transacción: #${response.data.data.id}.`)
                  this.anulated = true
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },
  },
}
</script>
